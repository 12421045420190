// sortable tree: wrapper
.rst__rowContents {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
}

// sortable tree: line color
.rst__lineHalfHorizontalRight::before, 
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after,
.rst__lineChildren::after {
  background: #bdbdbd;
}