/* appbar-popover */
.appbar-popover-content {
  padding: 6px 12px;
  overflow: visible !important;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    position: absolute;
    top: -20px;
  }
}

.appbar-popover-content-dark {
  padding: 6px 12px;
  overflow: visible !important;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #424242;
    border-left: 10px solid transparent;
    position: absolute;
    top: -20px;
  }
}

.timezone-popover-content {
  &:before {
    left: 47%;
  }
}
.i18n-popover-content {
  &:before {
    left: 63%;
  }
}
.guide-popover-content {
  &:before {
    right: 10px;
  }
}

/* copy */
.copy {
  cursor: pointer;
  position: relative;
  &:hover:before {
    content: 'Click to Copy';
    position: absolute;
    background: #fffabc;
    padding: 3px 8px 3px 5px;
    border: 1px solid #182026;
    border-radius: 3px;
    opacity: 1;
    top: -30px;
    right: 20px;
  }

  input,
  div {
    cursor: pointer;
  }
}

/* status indicators */
.status {
  &.Enable:before {
    background-color: #94e185;
    border-color: #78d965;
    box-shadow: 0px 0px 4px 1px #94e185;
  }

  &.Disable:before {
    background-color: #ffc182;
    border-color: #ffb161;
    box-shadow: 0px 0px 4px 1px #ffc182;
  }

  &.true:before {
    background-color: #94e185;
    border-color: #78d965;
    box-shadow: 0px 0px 4px 1px #94e185;
  }

  &.false:before {
    background-color: #aaa;
    border-color: #aaa;
    box-shadow: 0px 0px 4px 1px #aaa;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 10px;
    border: 1px solid #000;
    border-radius: 7px;
  }
}
